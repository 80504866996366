<template>
  <div class="header">
    <div class="title">
      <div style="position: absolute;">
        <div v-if="!isBack" @click="showMenu = true"><span class="iconfont icon-menu"></span></div>
        <div v-else style="display: flex; align-items: center; flex-direction: row;" @click="goBack"><van-icon name="arrow-left" /> {{ $t('back') }}</div>
      </div>
      <slot name="title"></slot>
    </div>

    <van-popup
      v-model="showMenu"
      position="left"
      :style="{ 'min-width': '120px', color: 'white', height: 'calc(100vh - 66px)', top: '65px', background: 'rgba(39, 102, 186, 0.8)' }"
    >
      <div class="menus">
        <div class="menus-item" @click="goSearch">{{ $t('accommodation') }}</div>
        <div class="menus-item" @click="goReservationList">{{ $t('bookings') }}</div>
        <div class="menus-item">
          <div>{{ $t('china') }}</div>
          <div class="gold">400-098-2996</div>
        </div>
        <div class="menus-item">
          <div>{{ $t('overseas') }}</div>
          <div class="gold">hotel@bjome.com.cn</div>
        </div>
<!--        <div class="menus-item">-->
<!--          <div>{{ $t('wechat') }}</div>-->
<!--          <div class="gold">dongfang</div>-->
<!--        </div>-->
        <div class="menus-item" @click="logout">
          {{ $t('signOut') }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { removeToken } from '@/utils/auth'

export default {
  name: 'Header',
  props: {
    isBack: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      showMenu: false
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    goSearch () {
      this.$router.push({ name: 'Search' }).catch(() => { window.location.reload() })
    },
    goReservationList () {
      this.$router.push({ name: 'ReservationList' }).catch(() => { window.location.reload() })
    },
    logout () {
      this.showMenu = false
      const _this = this
      setTimeout(() => {
        Dialog.confirm({
          title: _this.$t('signOut'),
          message: _this.$t('signOutConfirm')
        })
          .then(() => {
            removeToken()
            _this.$router.push({ name: 'Home' })
          })
          .catch(() => {
            // on cancel
          })
      }, 300)
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  box-sizing: border-box;
  background-color: #005DA9;
  padding: 20px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 64px;
  z-index: 20;
}

.toggle-menu {
  span {
    font-size: 26px;
  }
}

.title {
  flex: 1;
  text-align: center;
}

.van-popup {
  -webkit-transform: unset !important;
  transform: unset !important;
}

.menus {
  padding: 0 20px;
  .menus-item {
    border-bottom: 1px solid white;
    padding: 20px 0;
    text-align: center;
    .gold {
      color: #FFD700;
    }
  }
}
</style>
